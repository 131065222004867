import { Checkbox } from "components/commons";
import { prettifyProductCode, prettifyProductType } from "utils/pretty.utils";

export const mapDataToList = ({ product, onCheck, selected }) => {
  return {
    select: (
      <Checkbox
        name={product?.name}
        value={selected?.includes(product.sku)}
        onChange={(name, { value }) => onCheck?.(product.sku)}
      />
    ),
    sku: product.sku,
    productName: prettifyProductCode(product.name),
    variant: product.variant || "--",
    productType: prettifyProductType(product?.type),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { productType, ...fs } = filterState;

  if (productType?.value?.length > 0) {
    fs.type = productType?.value?.join(",");
  }

  if (productType?.isSelectedAll) {
    fs.type = undefined;
  }

  return fs;
};
