const AccessAuthorization = "/access-authorization";
const Promos = "/promos";
const Stations = "/stations";
const Path = {
  Slash: "/",
  Auth: "/auth",
  AccessAuthorization: AccessAuthorization,
  UserAccess: `${AccessAuthorization}/user-access`,
  Stations: Stations,
  PromosList: Promos,
  AddPromo: `${Promos}/add`,
  PromoDetails: `${Promos}/details/:id/:mode`,
};

export default Path;
