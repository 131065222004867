const ErrorCode = {
  IncorrectCredentials: "P1002",
  IncorrectProfileType: "P1026",
  StationPriceToolNotFound: "S1000",
  StationNotFound: "S1002",
  StationNotFoundv1: "S1001",
  StationHasNoDepot: "S1007",
};

export default ErrorCode;
