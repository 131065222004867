import { useCallback, useEffect, useState } from "react";
import ConfirmModal from "../confirm-modal/confirm-modal";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "./select-stations-filter-state";
import { stationsColumn } from "./select-station-columns";
import { mapDataToList, mapFilterToRequest } from "./select-stations-mapper";
import { getStations } from "apis";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import locale from "localization";
import { useApi } from "hooks";
import styles from "./select-stations-modal.module.scss";
import SelectStationsFilter from "./select-stations-filter";
import { handleRequest } from "utils";

const SelectStationsModal = (modal = { onFetch: (allStations) => 0, allStations: [] }) => {
  const [allStations, setAllStations] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selected, setSelected] = useState(modal?.value || []);

  const onCheck = (stationCode) => {
    if (selected.includes(stationCode)) {
      setSelected((s) => s.filter((code) => code !== stationCode));
    } else {
      setSelected((s) => [...s, stationCode]);
    }
  };

  // const uniqueStationType = useMemo(() => {
  //   return allStations
  //     ?.reduce((acc, { type }) => {
  //       if (!acc.some((p) => p.value === type)) {
  //         acc.push({ label: type, value: type });
  //       }
  //       return acc;
  //     }, [])

  // }, [allStations]);

  const handleOnCheckAll = () => {
    setIsCheckAll(!isCheckAll);
    let result = [];
    if (isCheckAll) {
      result = [];
    } else {
      result = allStations?.map((station) => station?.stationCode);
    }

    setSelected(result);
  };

  const onClickSave = () => {
    modal?.onChange?.(selected);
  };

  const getAllStations = useApi({
    api: getStations,
  });

  const { filter, search, table } = useDataTable({
    api: {
      api: getStations,
      mapper: mapFilterToRequest,
    },
    filter: {
      initialState: initialFilterState(),
      mapper: mapFilterToRequest,
    },
    table: {
      key: "stations",
      mapper: (station) =>
        mapDataToList({
          station,
          onCheck,
          selected,
        }),
      columns: stationsColumn,
    },
  });

  const fetchAllStations = useCallback(
    async (filterState) => {
      const res = await getAllStations?.request({
        ...mapFilterToRequest(filterState),
        perPage: 1000,
      });

      const stations = res.stations;
      if (selected.length > stations.length && isCheckAll) {
        setSelected(res.stations?.map((station) => station?.stationCode));
      }

      return stations;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCheckAll, selected]
  );

  useEffect(() => {
    handleRequest(async () => {
      search?.applyClearSearch();
      if (modal?.allStations && modal?.allStations?.length > 0) {
        setAllStations(modal?.allStations || []);
      } else {
        const allStationsRes = await fetchAllStations(initialFilterState());
        setAllStations(allStationsRes || []);
        modal?.onFetch?.(allStationsRes);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.allStations]);

  useEffect(() => {
    if (modal?.active && modal?.value && modal?.value?.length >= 0) {
      setSelected(modal?.value);
    }
  }, [modal?.value, modal?.active]);

  useEffect(() => {
    if (modal?.active) {
      search?.applyClearSearch();
      setIsCheckAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.active]);

  const applyClearSearch = async () => {
    setAllStations(
      await fetchAllStations({
        ...filter.filterState,
        searchKey: undefined,
        perPage: 1000,
        id: "modal",
      })
    );
    search.applyClearSearch();
  };

  const applyFilter = async () => {
    setAllStations(
      await fetchAllStations({
        ...filter.filterState,
        searchKey: "",
        perPage: 1000,
        id: "modal",
      })
    );

    filter?.applyFilter();
  };

  const applySearch = async () => {
    setAllStations(
      await fetchAllStations({
        ...filter.filterState,
        searchKey: search.searchKey,
        perPage: 1000,
        id: "modal",
      })
    );

    search.applySearch();
  };

  const applyClearFilter = async () => {
    setAllStations(
      await fetchAllStations({
        ...initialFilterState(),
        searchKey: search.searchKey || undefined,
        perPage: 1000,
        id: "modal",
      })
    );

    filter.applyClearFilter({
      searchKey: search.searchKey || undefined,
    });
  };

  return (
    <ConfirmModal
      {...modal}
      className={styles.modal}
      primary={{ text: locale.save, disabled: selected.length === 0, onClick: onClickSave }}
      secondary={{ text: locale.cancel }}
    >
      <SelectStationsFilter
        {...filter}
        {...search}
        applyClearSearch={applyClearSearch}
        applySearch={applySearch}
        applyFilter={applyFilter}
        applyClearFilter={applyClearFilter}
        actionText={<locale.Populate text={locale.nSelected} items={[selected.length]} />}
        actionProps={{
          link: selected.length > 0,
          variant: selected.length === 0 && "disabled",
        }}
        actionOnClick={() => {
          setSelected([]);
          setIsCheckAll(false);
        }}
        // uniqueStationType={uniqueStationType}
      />
      <DataTableV2
        {...table}
        onCheckAll={handleOnCheckAll}
        actionHeaderProps={{
          checked: isCheckAll,
        }}
        size="small"
      />
    </ConfirmModal>
  );
};

export default SelectStationsModal;
