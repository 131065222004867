import locale from "localization";

export const productsColumn = [
  {
    key: "select",
    className: "alignToTop",
    width: "50px",
    actionHeader: true,
  },
  {
    key: "sku",
    text: locale.sku,
    className: "alignToTop",
  },
  {
    key: "productName",
    text: locale.productName,
    className: "alignToTop",
    width: "30%",
  },
  {
    key: "variant",
    text: locale.variant,
    className: "alignToTop",
  },
  {
    key: "productType",
    text: locale.productType,
    className: "alignToTop",
  },
];
