export const checkIfObjectValuesAreEqual = (obj1, obj2) => {
  // sorting of keys must match, else this will not work
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const mapOptions = (values = [], prettifier = () => {}) => {
  return [
    ...values.map((value) => ({
      label: prettifier(value),
      value,
    })),
  ];
};

export const parseCSVFile = async (file, columns = []) => {
  const fileContent = await file.text();
  const rows = fileContent.split("\n");
  const errors = [];
  const rowErrors = [];
  const items = rows
    .filter((row, index) => {
      const r = row.replaceAll("\r", "").split(",");
      if (index === 0) {
        r.forEach((text, i) => {
          const columnText = columns[i]?.text;
          const isValid = text === columnText;
          if (!isValid) {
            errors.push(`Column ${i + 1} should be '${columnText}'`);
            rowErrors.push("header");
          }
        });
      }
      return index !== 0;
    })
    .map((row, index) => {
      const r = row.replaceAll("\r", "").split(",");
      const obj = {};
      r.forEach((text, i) => {
        obj[columns[i]?.key] = text;
      });
      const messages = [];
      r.forEach((text, i) => {
        if (columns[i]?.validation) {
          const errorStatus = columns[i]?.validation(text, obj);
          if (errorStatus.error) {
            messages.push(errorStatus.message);
          }
        }
      });
      if (messages.length) {
        errors.push(`Row ${index + 1}: ${messages.join(", ")}`);
        rowErrors.push(index + 1);
        obj.error = true;
      }
      return { ...obj, rowNumber: index + 1 };
    });
  return {
    items,
    passed: items.filter((item) => !item.error),
    errors,
    rowErrors,
  };
};

export const sortByKeyName = (key, string = true) => {
  return (a, b) => {
    if (a && b && a[key] && b[key]) {
      const first = string && a[key] ? a[key].toString().toLowerCase() : a[key];
      const second = string && b[key] ? b[key].toString().toLowerCase() : b[key];
      if (first < second) return -1;

      if (first > second) return 1;
    }

    return 0;
  };
};

export const isObject = (obj) => {
  return obj !== null && typeof obj === "object";
};

export const hasChanges = (obj1, obj2) => {
  if (obj1 === obj2) return false;

  if (!isObject(obj1) || !isObject(obj2)) return true;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return true;

  for (let key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    const areBothObjects = isObject(val1) && isObject(val2);

    if ((areBothObjects && hasChanges(val1, val2)) || (!areBothObjects && val1 !== val2)) {
      return true;
    }
  }

  return false;
};
