import StationType from "enums/station-type";

export const initialFilterState = () => {
  return {
    searchKey: "",
    page: 1,
    perPage: 10,
    stationType: {
      isSelectedAll: true,
      value: Object.values(StationType).map((stationType) => ({
        label: stationType,
        value: stationType,
      })),
    },
  };
};
