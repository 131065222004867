import { Path } from "enums";
import StationsModule from "modules/station/stations.module";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom/cjs/react-router-dom.min";

const StationsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Stations} component={StationsModule} />
      <Redirect to={Path.Stations} />
    </Switch>
  );
};

export default StationsPage;
