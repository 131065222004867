import { Text, Pill, PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { dateTimeFormat } from "utils";
import { locale } from "localization/en";
import styles from "./stations.module.scss";
import StationStatus from "enums/station-status";
import { prettifyStationStatus, prettifyStationSubscription } from "utils/pretty.utils";

export const mapDataToList = ({ stations, handleOnClickActionOption }) => {
  const { stationCode, name, type, subscription, onboardedBy, onboardedAt, status } = stations;

  const options = [
    {
      removable: true,
      content: locale.viewDetails,
      onClick: () => {},
    },
  ];

  const activate = () => {
    return {
      removable: true,
      content: locale.activate,
      onClick: () => {
        handleOnClickActionOption?.(stations, StationStatus.ACTIVE);
      },
    };
  };

  const deactivate = () => {
    return {
      removable: true,
      content: locale.deactivate,
      onClick: () => {
        handleOnClickActionOption?.(stations, StationStatus.DEACTIVATED);
      },
    };
  };

  if (status === StationStatus.ACTIVE) {
    options.push(deactivate());
  }

  if (status === StationStatus.PENDING || status === StationStatus.MANUAL_MODE) {
    options.push(activate());
    options.push(deactivate());
  }

  return {
    code: <Text className={styles.td}>{stationCode ?? ""}</Text>,
    name: <Text className={styles.td}>{name ?? "--"}</Text>,
    type: <Text className={styles.td}>{type ?? "--"}</Text>,
    subscription: (
      <Text className={styles.td}>{prettifyStationSubscription(subscription) ?? "--"}</Text>
    ),
    onboardedBy: (
      <>
        <Text className={styles.td}>{onboardedBy ?? "--"}</Text>
        <Text className={styles.subtitle}>{dateTimeFormat(onboardedAt ?? "")}</Text>
      </>
    ),
    status: (
      <>
        <Pill
          sky={status === StationStatus.PENDING}
          cheddar={status === StationStatus.MANUAL_MODE}
          cement={status === StationStatus.DEACTIVATED}
          grass={status === StationStatus.ACTIVE}
        >
          {prettifyStationStatus(status)}
        </Pill>
        <Text className={styles.subtitle}>{dateTimeFormat(onboardedAt ?? "")}</Text>
      </>
    ),
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;

  return {
    ...fs,
  };
};
