import classNames from "classnames";
import React from "react";
import styles from "./text.module.scss";

const Text = ({
  style,
  children,
  label,
  danger,
  strike,
  className,
  subtitle,
  align,
  strong,
  italic,
  color,
}) => {
  const classes = classNames(styles.text, className, {
    [`${styles.label}`]: label,
    [`${styles.subtitle}`]: subtitle,
    [`${styles.strong}`]: strong,
    [`${styles.italic}`]: italic,
    [`${styles.danger}`]: danger,
    [`${styles.strike}`]: strike,
  });
  const mergedStyle = {
    textAlign: align,
    ...style,
    color: color,
  };
  return (
    <div style={mergedStyle} className={classes}>
      {children}
    </div>
  );
};

export default Text;
