import React, { useEffect, useCallback, useMemo } from "react";
import { Intro } from "components/commons";
import locale from "localization";
import { getStations, updateStationStatus } from "apis";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "./stations-filter.state";
import { mapDataToList, mapFilterToRequest } from "./stations.mapper";
import { stationsColumn } from "./stations-column";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import styles from "./stations.module.scss";
import StationsFilter from "./stations-filter";
import { useModal, useForm, useApi } from "hooks";
import UpdateStationStatusModal from "./station-status-modal/station-status-modal";
import Validation from "utils/validation.utils";
import { StationStatus } from "enums";
import ConfirmModal from "components/modals/confirm-modal/confirm-modal";

const StationsModule = () => {
  const updateStationStatusModal = useModal();
  const successModal = useModal();

  const updatePromoStatus = useApi({
    api: updateStationStatus,
  });

  const initialState = useMemo(() => {
    return {
      reason: {
        name: "reason",
        value: "",
        placeholder: "Select",
        required: true,
        validations: [Validation.required()],
      },
      remarks: {
        name: "remarks",
        value: "",
        placeholder: locale.typeRemarks,
        rowsMax: 7,
        validations: [Validation.maxlength(225)],
      },
    };
  }, []);

  const updateStationForm = useForm({
    initialState,
  });

  const handleOnClickActionOption = (station, status) => {
    updateStationForm.clearForm();
    updateStationStatusModal.show({
      title: `${
        status === StationStatus.ACTIVE ? locale.activateStation : locale.deactivateStation
      }?`,
      content: (
        <locale.Populate
          text={
            status === StationStatus.ACTIVE
              ? locale.activateStationContent
              : locale.deactivateStationContent
          }
          items={[
            <b>
              <br />
              {status === StationStatus.ACTIVE ? station.name : ""}
            </b>,
          ]}
        />
      ),
      primary: {
        text: status === StationStatus.ACTIVE ? locale.yesActivate : locale.deactivateStation,
      },
      secondary: {
        text: locale.cancel,
      },
      stationId: station?.stationId,
      status: status,
    });
  };

  const { filter, search, table } = useDataTable({
    api: {
      api: getStations,
      params: {
        page: 1,
        perPage: 10,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "stations",
      mapper: (stations) => mapDataToList({ stations, handleOnClickActionOption }),
      columns: stationsColumn,
    },
  });

  const disableSubmitUpdateStationStatus = useMemo(() => {
    if (updateStationStatusModal.status === StationStatus.ACTIVE) {
      return true;
    }
    const isReasonFieldEmpty = updateStationForm.fields?.reason.value === "";
    const isOthersReasonEmpty =
      updateStationForm.fields?.reason.value === locale.others &&
      !updateStationForm.fields?.remarks.value;

    return updatePromoStatus?.loading || isOthersReasonEmpty || isReasonFieldEmpty;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateStationForm.fields?.reason.value,
    updateStationForm.fields?.remarks.value,
    updateStationForm.isFormSubmittable,
    updatePromoStatus?.loading,
  ]);

  const handleSubmitUpdateStationStatus = useCallback(async () => {
    const { fields } = updateStationForm;
    await updatePromoStatus.request({
      stationId: updateStationStatusModal.stationId,
      query: {
        status: updateStationStatusModal.status,
        remarks: fields?.remarks?.value ?? "",
        reason: fields?.reason?.value ?? "",
      },
    });
  }, [updateStationStatusModal, updateStationForm, updatePromoStatus]);

  useEffect(() => {
    if (updatePromoStatus?.called && !updatePromoStatus?.error) {
      updateStationStatusModal.close();
      table?.refetch();
      successModal?.show({
        titleStyle: styles.textNight,
        contentStyle: styles.textNight,
        title: locale.exclamatedSuccess,
        content: (
          <locale.Populate
            text={locale.updateStationStatusSuccessMessage}
            items={[
              <b>
                {updateStationStatusModal.status === StationStatus.ACTIVE
                  ? "activated"
                  : "deactivated"}
              </b>,
            ]}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePromoStatus?.error, updatePromoStatus?.called]);

  return (
    <>
      <UpdateStationStatusModal
        {...updateStationStatusModal}
        primary={{
          ...updateStationStatusModal?.primary,
          disabled: disableSubmitUpdateStationStatus,
          onClick: handleSubmitUpdateStationStatus,
          loading: updatePromoStatus?.loading,
        }}
        form={updateStationForm}
      />
      <ConfirmModal {...successModal} />
      <Intro title={locale.stations} actionText={locale.addStation} />
      <StationsFilter {...filter} {...search} />

      <div className={styles.table}>
        <DataTableV2 {...table} />
      </div>
    </>
  );
};

export default StationsModule;
