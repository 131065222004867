import locale from "localization";

export const initialState = {
  email: {
    name: "email",
    placeholder: "example@email.com",
    value: localStorage.getItem("email") || "",
    required: true,
    maxLength: 75,
    label: locale.emailAddress,
  },
  password: {
    name: "password",
    placeholder: "Password",
    value: "",
    required: true,
    maxLength: 75,
    label: locale.password,
  },
  remember: {
    name: "remember",
    value: localStorage.getItem("email") ? true : false,
    label: locale.rememberMe,
  },
};
