import { Autocomplete, Field, TextField } from "components/commons";
import locale from "localization/en";
import styles from "./station-status-modal.module.scss";
import ConfirmModal from "components/modals/confirm-modal/confirm-modal";
import Validation from "utils/validation.utils";
import { useMemo } from "react";
import { StationStatus } from "enums";

const UpdateStationStatusModal = (modalState = {}) => {
  const { fields, modifyField } = modalState?.form;
  const { status } = modalState;

  const remarksValidations = useMemo(() => {
    let validations = fields?.remarks?.validations || [];
    if (fields?.reason?.value === locale.others) {
      validations.push(Validation.required());
    }
    return validations;
  }, [fields]);

  return (
    <ConfirmModal
      {...modalState}
      className={styles.modal}
      titleStyle={styles.textNight}
      contentStyle={[styles.contentStyle]}
    >
      {status === StationStatus.DEACTIVATED && (
        <>
          <Field
            label={
              <>
                {locale.reason}
                <span style={{ color: "red", marginLeft: "5px" }}>*</span>
              </>
            }
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              options={[
                {
                  label: locale.endOfContract,
                  value: locale.endOfContract,
                },
                {
                  label: locale.stationClosed,
                  value: locale.stationClosed,
                },
                {
                  label: locale.others,
                  value: locale.others,
                },
              ]}
              {...fields?.reason}
              onChange={modifyField}
            />
          </Field>
          <Field
            label={locale.remarks}
            childrenClassName={styles.fieldContent}
            labelPosition={"left"}
            className={styles.reasonRemarks}
          >
            <TextField
              multiline
              {...fields?.remarks}
              validations={remarksValidations}
              // value={fields?.reason?.value === locale.others ? fields?.remarks?.value : ""}
              // inputDisabled={fields?.reason?.value !== locale.others}
              // disabled={fields?.reason?.value !== locale.others}
              // onChange={fields?.reason?.value === locale.others ? modifyField : undefined}
              value={fields?.remarks?.value}
              onChange={modifyField}
            />
          </Field>
        </>
      )}
    </ConfirmModal>
  );
};

export default UpdateStationStatusModal;
