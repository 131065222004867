import { Text } from "components/commons";
import locale from "localization/en";

export const columns = [
  {
    key: "promoDate",
    text: (
      <Text color={"#000"} style={{ width: "100px" }}>
        {locale.promoDateAndTime}
      </Text>
    ),
    width: "13%",
    className: "alignToTop",
    style: { textAlign: "top" },
  },
  { key: "promoName", text: locale.promoName, width: "15%", className: "alignToTop" },
  { key: "promoType", text: locale.promoType, className: "alignToTop", width: "10%" },
  {
    key: "product",
    text: (
      <Text color={"#000"}>
        {locale.product} /<br />
        {locale.promoAmount}
      </Text>
    ),
    className: "alignToTop",
    width: "10%",
    // width: "10%",
  },
  // {
  //   key: "promoAmount",
  //   text: (
  //     <Text color={"#000"} style={{ width: "70px" }}>
  //       {locale.promoAmount}
  //     </Text>
  //   ),
  //   width: "100px",
  //   className: "alignToTop",
  // },
  {
    key: "transactionCap",
    text: (
      <Text style={{ width: "90px" }} color={"#000"}>
        {locale.maxDiscount}
      </Text>
    ),
    // width: "110px",
    className: "alignToTop",
  },
  {
    key: "participatingStations",
    text: locale.participatingStations,
    width: "15%",
    className: "alignToTop",
  },
  {
    key: "createdBy",
    text: (
      <>
        <Text color={"#000"}>{locale.createdBy} /</Text>
        <Text color={"#000"}>{locale.createdOn}</Text>
      </>
    ),
    // width: "130px",
    className: "alignToTop",
  },
  {
    key: "status",
    text: (
      <>
        <Text color={"#000"}>{locale.status} / </Text>
        <Text color={"#000"}>{locale.dateOfLastUpdated}</Text>
      </>
    ),
    className: "alignToTop",
  },
  {
    key: "reasonRemarks",
    text: (
      <>
        <Text color={"#000"} style={{ width: "80px" }}>
          {locale.reason} / <br />
          {locale.remarks}
        </Text>
      </>
    ),
    width: "8%",
    className: "alignToTop",
  },
  { key: "actions", text: locale.actions, width: "auto", className: "alignToTop" },
];
