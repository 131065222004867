const PromoTypeEnum = {
  // DiscountPerLiter: "discount-per-liter",
  DiscountPerTransaction: "discount-per-transaction",
  DiscountPerUnit: "discount-per-unit",
  PercentDiscount: "percent-discount",
};

export const PromoTypeToApiEnum = {
  fixed: PromoTypeEnum.DiscountPerUnit,
  percentage: PromoTypeEnum.PercentDiscount,
  transaction: PromoTypeEnum.DiscountPerTransaction,
  unit: PromoTypeEnum.DiscountPerUnit,
  [PromoTypeEnum.PercentDiscount]: "percentage",
  [PromoTypeEnum.DiscountPerTransaction]: "transaction",
  [PromoTypeEnum.DiscountPerUnit]: "fixed",
};

export default PromoTypeEnum;
