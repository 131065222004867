const ProductCode = {
  DIESEL: "diesel", //extreme diesel
  GAS91: "gas91", // extreme u
  GAS95: "gas95", // extreme 95
  GAS97: "gas97", // extreme 97
  LUBRICANTS: "lubricants",
  LPG: "lpg",
};

export default ProductCode;
