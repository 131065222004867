export const initialFilterState = () => {
  return {
    startDate: null,
    endDate: null,
    searchKey: "",
    order: "desc",
    type: "",
    subscriptions: "",
    status: "",
    perPage: 10,
  };
};
