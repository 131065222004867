import { Field, Pill, PopOver, Product, Text, Title } from "components/commons";
import styles from "./promo-details.module.scss";
import locale from "localization";
import { formatAmount, formatDate, timeFormat } from "utils";
import { prettifyPromoStatus, prettifyPromoType } from "utils/pretty.utils";
import { ProductCodeEnum, PromoStatusEnum, PromoTypeEnum } from "enums";
import useProducts from "hooks/useProducts";
import { useEffect, useState } from "react";

const ViewPromo = (promo) => {
  const { status, participatingStations } = promo;
  const { allProducts } = useProducts();

  const renderStatus = () => {
    if (!status) return "-";

    return (
      <div>
        <Pill
          sky={status === PromoStatusEnum.Ongoing}
          cheddar={status === PromoStatusEnum.Upcoming}
          cement={[PromoStatusEnum.Ended, PromoStatusEnum.Cancelled].includes(status)}
        >
          {prettifyPromoStatus(status)}
        </Pill>
        {/* <Text
          style={{
            marginTop: "5px",
          }}
          subtitle
        >
          {dateTimeFormat(status === PromoStatusEnum.Upcoming ? createdAt : updatedAt)}
        </Text> */}
      </div>
    );
  };

  const renderPromoProducts = () => {
    return promoDetails?.product?.map(({ sku, name, value }, index) => {
      const product = allProducts.find((p) => p.sku === sku);
      const label = name || product.name;
      return (
        <div className={styles.viewProductInput}>
          <Product
            grass={sku === ProductCodeEnum.GAS91}
            salsa={sku === ProductCodeEnum.GAS95}
            deepBlue={sku === ProductCodeEnum.GAS97}
            cheddar={sku === ProductCodeEnum.DIESEL}
            purple={!Object.values(ProductCodeEnum).includes(sku)}
          >
            <Text className={styles.textNight}>{label}</Text>
          </Product>
          <Text align={"left"} className={styles.textNight}>
            {product?.variant && product.variant !== "NULL" ? product?.variant : "--"}
          </Text>
          <Text align={"left"} strong className={styles.textNight}>
            {formatAmount(value)}
          </Text>
        </div>
      );
    });
  };

  const [promoDetails, setPromoDetails] = useState(promo);

  const promoType = promo.promoType;

  useEffect(() => {
    if (promoType === PromoTypeEnum.PercentDiscount) {
      setPromoDetails((p) => ({ ...p, minPurchase: null }));
    }

    if (promoType === PromoTypeEnum.DiscountPerTransaction) {
      setPromoDetails((p) => ({ ...p, transactionCap: null }));
    }

    if (promoType === PromoTypeEnum.DiscountPerUnit) {
      setPromoDetails((p) => ({ ...p, transactionDiscount: null, minPurchase: null }));
    } else {
      setPromoDetails((p) => ({ ...p, product: null }));
    }
  }, [promoType]);

  return (
    <div>
      <Title className={styles.subtitle}>{locale.promoDetails}</Title>
      <div className={styles.container}>
        <div>
          <Field
            label={locale.promoDuration}
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Text color={"#000"} strong>
              {formatDate(promoDetails?.promoDate?.startDate)},{" "}
              {formatDate(promoDetails?.promoDate?.endDate)}
            </Text>
          </Field>
          <Field
            label={locale.promoTime}
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Text color={"#000"} strong>
              {timeFormat(promoDetails?.promoDate?.startDate)},{" "}
              {timeFormat(promoDetails?.promoDate?.endDate)}
            </Text>
          </Field>
          <Field
            label={locale.promoName}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Text color={"#000"} strong>
              {promoDetails?.promoName}
            </Text>
          </Field>
          <Field
            label={locale.promoType}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Text color={"#000"} strong>
              {prettifyPromoType(promoDetails?.promoType)}
            </Text>
          </Field>
          {promoDetails?.minPurchase && (
            <Field
              label={locale.minPurchase}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <Text color={"#000"} strong>
                {formatAmount(promoDetails?.minPurchase)}
              </Text>
            </Field>
          )}
          {promoDetails?.transactionDiscount && (
            <Field
              label={locale.promoAmount}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <Text color={"#000"} strong>
                {formatAmount(promoDetails?.transactionDiscount)}
              </Text>
            </Field>
          )}
          {promoDetails?.transactionCap && (
            <Field
              label={locale.maxDiscount}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <Text color={"#000"} strong>
                {promoDetails?.transactionCap > 0
                  ? formatAmount(promoDetails?.transactionCap)
                  : locale.noLimit}
              </Text>
            </Field>
          )}
          <Field
            label={locale.promoStatus}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Text>{renderStatus()}</Text>
          </Field>
        </div>
      </div>
      {promoDetails?.promoType === PromoTypeEnum.DiscountPerUnit && (
        <>
          <Title className={styles.subtitle}>{locale.products}</Title>
          <div style={{ width: "50%" }}>
            <div className={styles.viewProductInput}>
              <div>{locale.productName}</div>
              <div>{locale.variant}</div>
              <div></div>
            </div>
            <div>{renderPromoProducts()}</div>
          </div>
        </>
      )}
      <div className={styles.container} style={{ marginTop: "-5px" }}>
        <div className={styles.viewPromoParticipatingStationContainer}>
          <Title className={styles.subtitle}>{locale.participatingStations}</Title>
          <PopOver
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            contentStyle={{
              boxShadow: '10px 0px 20px rgba(0, 0, 0, 0.1)', 
            }}
            arrowClassNames={styles.arrow}
            className={styles.popoverContainer}
            content={
              <div className={styles.popOver}>
                <Text color={"slateblue"} strong>
                  {locale.participating}
                </Text>
                {participatingStations?.map(({ stationCode, name }, i) => (
                  <Text className={styles.textNight} key={`${stationCode}-${i}`}>
                    {name}
                  </Text>
                ))}
              </div>
            }
          >
            <div
              className="link"
              style={{ color: "slateblue", marginLeft: "2.5em", marginTop: "-5px" }}
            >
              <b>
                {participatingStations?.length} {participatingStations?.length > 1 ? locale.stations : locale.station}
              </b>
            </div>
          </PopOver>
        </div>
      </div>
    </div>
  );
};

export default ViewPromo;
