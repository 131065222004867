import React from "react";
import styles from "./select-products-modal.module.scss";
import { Field, Filter, SearchField, Autocomplete, Button } from "components/commons";
import locale from "localization";
import { Close } from "@material-ui/icons";

const SelectProductsFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  resetFilter,
  countSelected = 0,
  actionText,
  actionProps,
  actionOnClick,
  uniqueProductTypes = [],
  disabled = { productType: false },
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
          }}
          filterCount={filterCount}
        >
          <Field
            label={locale.productType}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"productType"}
              hasAll
              multiple
              {...filterState?.productType}
              options={uniqueProductTypes}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
              disabled={disabled.productType}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={[locale.sku, locale.productName].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        {actionText && (
          <Field className={styles.clearSelectedButton}>
            <Button onClick={actionOnClick} link {...(actionProps || {})}>
              {actionText}
              <Close fontSize="small" />
            </Button>
          </Field>
        )}
      </div>
    </div>
  );
};

export default SelectProductsFilter;
