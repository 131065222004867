import { Autocomplete, Field, TextField } from "components/commons";
import ConfirmModal from "components/modals/confirm-modal/confirm-modal";
import locale from "localization";
import styles from "./cancel-promo-modal.module.scss";
import { prettifyCancelPromoReason } from "utils/pretty.utils";
import { PromoCancelReasonEnum } from "enums";
import Validation from "utils/validation.utils";
import { useMemo } from "react";

const CancelPromoConfirmModal = (modalState = {}) => {
  const { fields, modifyField } = modalState?.form;

  const remarksValidations = useMemo(() => {
    let validations = fields?.remarks?.validations || [];
    if (fields?.reason?.value === PromoCancelReasonEnum.OTHERS) {
      validations.push(Validation.required());
    }
    return validations;
  }, [fields]);

  return (
    <ConfirmModal
      {...modalState}
      className={styles.modal}
      titleStyle={styles.textNight}
      contentStyle={[styles.contentStyle]}
    >
      <Field
        label={
          <>
            {locale.reason}
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          </>
        }
        labelPosition={"left"}
        childrenClassName={styles.fieldContent}
      >
        <Autocomplete
          options={[
            {
              label: prettifyCancelPromoReason(PromoCancelReasonEnum.INCORRECT),
              value: PromoCancelReasonEnum.INCORRECT,
            },
            {
              label: prettifyCancelPromoReason(PromoCancelReasonEnum.DUPLICATE),
              value: PromoCancelReasonEnum.DUPLICATE,
            },
            {
              label: prettifyCancelPromoReason(PromoCancelReasonEnum.OTHERS),
              value: PromoCancelReasonEnum.OTHERS,
            },
          ]}
          {...fields?.reason}
          onChange={modifyField}
        />
      </Field>
      <Field
        label={locale.remarks}
        childrenClassName={styles.fieldContent}
        labelPosition={"left"}
        className={styles.reasonRemarks}
      >
        <TextField
          multiline
          {...fields?.remarks}
          validations={remarksValidations}
          value={
            fields?.reason?.value === PromoCancelReasonEnum.OTHERS ? fields?.remarks?.value : ""
          }
          inputDisabled={fields?.reason?.value !== PromoCancelReasonEnum.OTHERS}
          disabled={fields?.reason?.value !== PromoCancelReasonEnum.OTHERS}
          onChange={
            fields?.reason?.value === PromoCancelReasonEnum.OTHERS ? modifyField : undefined
          }
        />
      </Field>
    </ConfirmModal>
  );
};

export default CancelPromoConfirmModal;
