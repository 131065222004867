import React from "react";
import styles from "./footer.module.scss";
import { Text } from "components/commons";

const Footer = () => {
  return (
    <div className={styles.container}>
      <Text className={styles.text}>© Copyright · 2024 All Rights Reserved</Text>
    </div>
  );
};

export default Footer;
