const ApiPath = {
  Login: "ms-profile/user/login",
  Logout: "ms-profile/user/logout",
  Refresh: "ms-profile/user/refresh",
  GetUser: "ms-profile/user",
  GetUsers: "ms-profile/user/users",
  Station: "ms-pos/station",
  DailyRecords: "ms-pos/daily-record",
  Transaction: "ms-pos/transaction",
  Promo: "ms-pos-promo/promo",
  Product: "ms-stock/product",
  Category: "ms-stock/category",
};

export default ApiPath;
