import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getPromoListApi = async (query) => {
  const res = await Get(`${ApiPath.Promo}/admin`, query);
  return res.data;
};

export const changePromoStatusApi = async ({ promoId, body }) => {
  const res = await Put(`${ApiPath.Promo}/admin/${promoId}/status`, body);
  return res.data;
};

export const addPromoApi = async ({ body }) => {
  const res = await Post(`${ApiPath.Promo}/admin`, body);
  return res.data;
};

export const getPromoDetailByIdApi = async (query) => {
  const res = await Get(`${ApiPath.Promo}/${query?.promoId}`, query);
  return res.data;
};

export const editPromoApi = async ({ promoId, body }) => {
  const res = await Put(`${ApiPath.Promo}/admin/${promoId}`, body);
  return res.data;
};
