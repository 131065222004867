/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@material-ui/core";
import TimePickerV2 from "./time-picker-v2";
import { useMemo } from "react";
import moment from "moment";
import { combineDateAndTimeInput } from "utils";

export const isDifferentDay = (date1, date2) => {
  const d1 = moment(date1).format("YYYY-MM-DD");
  const d2 = moment(date2).format("YYYY-MM-DD");

  return d1 !== d2;
};

export const getMinTime = ({ startTime, endTime, minutesGap, currentTime, endDate }) => {
  if (isDifferentDay(startTime, endTime || endDate)) {
    return undefined;
  }

  if (!endTime) {
    if (startTime && startTime > currentTime) {
      return moment(startTime).add(minutesGap, "minutes").toDate();
    } else {
      return moment(currentTime).add(minutesGap, "minutes").toDate();
    }
  }

  if (startTime && startTime > endTime) {
    return moment(startTime).add(minutesGap, "minutes").toDate();
  }

  if (startTime && startTime > currentTime) {
    return moment(startTime).add(minutesGap, "minutes").toDate();
  }

  return moment(currentTime).add(minutesGap, "minutes").toDate();
};

const TimePickerRange = ({ startTimeProps, endTimeProps, minutesGap = 0, ...props }) => {
  const currentTime = new Date();

  const handleStartTimeChange = (name, { value }) => {
    const newStartTime = combineDateAndTimeInput(startTimeProps.date, value);
    startTimeProps?.onChange?.(name, {
      value: newStartTime,
      minTime: getMinTime({
        startTime: newStartTime,
        endTime: endTime,
        minutesGap,
        currentTime,
        endDate: endTimeProps?.date,
      }),
    });
  };

  const handleEndTimeOnChange = (name, { value }) => {
    const newEndTime = combineDateAndTimeInput(endTimeProps.date, value);
    endTimeProps?.onChange?.(name, { value: newEndTime });
  };

  const startTime = useMemo(() => {
    if (startTimeProps?.date && startTimeProps?.value) {
      return combineDateAndTimeInput(startTimeProps?.date, startTimeProps?.value);
    }

    if (startTimeProps?.value) {
      return startTimeProps?.value;
    }

    if (startTimeProps?.date) {
      return startTimeProps?.date;
    }
  }, [startTimeProps]);

  const endTime = useMemo(() => {
    if (!endTimeProps?.value) return null;

    if (endTimeProps?.date && endTimeProps?.value) {
      return combineDateAndTimeInput(endTimeProps?.date, endTimeProps?.value);
    }

    if (endTimeProps?.value) {
      return endTimeProps?.value;
    }

    if (endTimeProps?.date) {
      return endTimeProps?.date;
    }
  }, [endTimeProps]);

  const minOfEndTime = useMemo(() => {
    const newMinTime = getMinTime({
      startTime,
      endTime,
      minutesGap,
      currentTime,
      endDate: endTimeProps?.date,
    });
    return newMinTime;
  }, [startTime, endTime, currentTime, minutesGap, startTimeProps, endTimeProps]);

  return (
    <Box display="grid" gridTemplateColumns={"8fr 1fr 8fr"} gridGap={"1em"}>
      <Box>
        <TimePickerV2
          {...startTimeProps}
          value={startTime}
          onChange={handleStartTimeChange}
          onError={() => {
            startTimeProps?.onError?.();
          }}
        />
      </Box>
      <Box textAlign={"center"} alignContent={"center"}>
        To
      </Box>
      <Box>
        <TimePickerV2
          {...endTimeProps}
          onChange={handleEndTimeOnChange}
          value={endTime}
          disabled={!startTimeProps?.value || endTimeProps?.disabled}
          minTime={minOfEndTime}
          onError={() => {
            endTimeProps?.onError?.();
          }}
        />
      </Box>
    </Box>
  );
};

export default TimePickerRange;
