import { PromoStatusEnum, PromoTypeEnum } from "enums";

export const initialFilterState = () => ({
  searchKey: "",
  page: 1,
  perPage: 10,
  startDate: null,
  endDate: null,
  promoType: {
    isSelectedAll: true,
    value: [PromoTypeEnum.DiscountPerUnit],
  },
  status: {
    isSelectedAll: true,
    value: Object.values(PromoStatusEnum),
  },
});
