import { Path } from "enums";
import PromoDetailsModule from "modules/promos/promo-details/promo-details.module";
import PromosListModule from "modules/promos/promos-list.module";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom/cjs/react-router-dom.min";

const PromosPage = () => {
  return (
    <Switch>
      <Route exact path={Path.PromosList} component={PromosListModule} />
      <Route path={Path.AddPromo} component={PromoDetailsModule} />
      <Route path={Path.PromoDetails} component={PromoDetailsModule} />
      <Redirect to={Path.PromosList} />
    </Switch>
  );
};

export default PromosPage;
