export const formatMobileNumber = (string) => {
  return string?.toString().replace("+63", "0");
};

export const formatName = (firstName = "", lastName = "") => {
  return `${firstName ? firstName : ""} ${lastName ? lastName : ""}`.trim();
};

export const capitalize = (string = "") => {
  return `${string[0]?.toString().toUpperCase()}${string?.substring(1).toString().toLowerCase()}`;
};

export const capitalizeWord = (str = "") => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const capitalizeAll = (string = "") => {
  return string?.toString().toUpperCase();
};

export const pluralize = (count, singular, plural) => {
  return count >= 2 ? plural : singular;
};

export const numberWithComma = (string) => {
  const regex = /^(?:\d+(?:,\d+)*,?)?$/;
  return regex.test(string);
};

export const formatNumberToOrdinal = (num = 0) => {
  let lastDigit = Number(num.toString().charAt(num.toString().length - 1));
  if ([11, 12, 13].includes(num)) {
    lastDigit = null;
  }
  switch (lastDigit) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export const isEmailValid = (email) => {
  const regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  let isValid = regex.test(email.toLowerCase().trim());

  if (isValid) {
    const id = email.toString().split("@");
    if (id?.[0]) {
      const test = id?.[0];
      const invalids = [".", "-", "_", "#"];
      if (invalids.includes(test.charAt(0)) || invalids.includes(test.charAt(test.length - 1))) {
        isValid = false;
      }
      invalids.forEach((item) => {
        if (test.toString().includes("#") || test.toString().includes(item + item)) {
          console.log("heyy");
          isValid = false;
        }
      });
    }
  }
  return isValid;
};

export const isAlphaNumeric = (text) => {
  const regex = new RegExp(/[^A-Za-z0-9 ]/);
  return !regex.test(text);
};

export const isAlphaNumericWithSymbols = (text) => {
  const regex = new RegExp(/[^A-Za-z0-9Ññ.,'-\s-]/);
  return !regex.test(text);
};

export const isNameValid = (text) => {
  const regex = new RegExp(/[^A-Za-z0-9Ññ.-\s-]/);
  return !regex.test(text);
};

export const isSearchFilterValid = (text) => {
  return text.replace(/[^A-Za-z0-9Ññ@.,'_+-\s-]/g, "");
};

export const isNumeric = (text) => {
  const regex = new RegExp(/[^0-9]/);
  return !regex.test(text);
};

export const isMobileNumberValid = (mobile) => {
  if (mobile.substring(0, 2) !== "09") {
    return false;
  }
  const regex = new RegExp(/^[09]+?\d{10}$/);
  return regex.test(mobile.trim());
};

export const isMobileNumberStrictValid = (mobile = "") => {
  if (mobile.substring(0, 4) !== "+639") {
    return false;
  }
  const num = mobile.substring(4);
  return isNumeric(num) && mobile.length === 13;
};

export const isDriverLicenseIdValid = (mobile) => {
  const regex = new RegExp(/^([A-Z]{1})+?\d{10}$/);
  return regex.test(mobile.trim());
};

export const formatAmount = (num = 0, prefix = "₱", minFraction = 2, maxFraction = 2) => {
  if (!isNaN(Number(num))) {
    return `${prefix} ${Number(num).toLocaleString("en", {
      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
    })}`;
  }
  return `${prefix} ${Number("0").toLocaleString("en", {
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  })}`;
};

export const formatPriceMovementPerLiter = (num) => {
  let sign = "";
  switch (num) {
    case 0:
      sign = "";
      break;
    default:
      sign = Math.sign(num) > 0 ? "+" : "-";
  }

  return `${sign}₱ ${Number(Math.abs(num)).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}/L`;
};

export const isPasswordValid = (password) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d@$!%*?&-_#%^()+<> ])[A-Za-z\d@$!%*?&-_#%^()+<> ]{8,}$/;
  return regex.test(password);
};

export const isTelephoneNumber = (telephone, limit = 15) => {
  const regex = /[0-9(+)-]+$/;
  return regex.test(telephone) && maxLength({ value: telephone, limit });
};

export const maxLength = (obj) => {
  const { value, limit } = obj;
  if (value.length > limit) {
    return false;
  }
  return true;
};

export const keypress = (key, action) => {
  const onKeyup = (e) => {
    if (e.key === key) action();
  };
  window.addEventListener("keyup", onKeyup);
  return () => window.removeEventListener("keyup", onKeyup);
};

export const formatNamePossessive = (name = "") => {
  const text = `${name}'`;
  return name.length && name[name.length - 1].toLowerCase() === "s" ? text : `${text}s`;
};

export const formatPumpNumber = (pumpNumber) => {
  const text = pumpNumber.replace(/"/g, "");
  return text;
};

export const stringToDecimal = (input) => {
  const cleanedString = input.replace(/[^\d.]/g, "");
  const decimalNumber = parseFloat(cleanedString);
  return isNaN(decimalNumber) ? null : decimalNumber;
};
