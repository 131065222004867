import { ApiPath } from "enums";
import { Get, Put } from "utils";

export const getStations = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Station}`, query);
  return res.data;
};

export const getStationsPricing = async ({ ...query }) => {
  const res = await Get(`${ApiPath.StationPricing}`, query);
  return res.data;
};

export const updateStationStatus = async ({ stationId, query }) => {
  const res = await Put(`${ApiPath.Station}/${stationId}`, query);
  return res.data;
};
