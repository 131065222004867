import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const useLeavePageBlocker = (callBack, dependencies = [], { isListen = false }) => {
  const history = useHistory();
  const prevLocation = useLocation().pathname;
  useEffect(() => {
    const unlisten = isListen
      ? history.listen((location) => {
          if (location.pathname !== prevLocation) {
            history.push(prevLocation);
            callBack?.(location.pathname);
          }
        })
      : () => 0;

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, callBack, isListen, ...dependencies]);
};

export default useLeavePageBlocker;
