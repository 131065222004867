import locale from "localization";

export const stationsColumn = [
  {
    key: "select",
    className: "alignToTop",
    actionHeader: true,
    width: "50px",
  },
  {
    key: "stationCode",
    text: locale.stationCode,
    className: "alignToTop",
  },
  {
    key: "stationName",
    text: locale.stationName,
    className: "alignToTop",
    width: "300px",
  },
  {
    key: "stationType",
    text: locale.stationType,
    className: "alignToTop",
  },
];
