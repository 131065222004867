export const initialFilterState = () => {
  return {
    searchKey: "",
    page: 1,
    perPage: 10,
    productType: {
      isSelectedAll: true,
      value: [],
    },
  };
};
