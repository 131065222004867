import { GuyWithMagGlass } from "images";
import locale from "localization";
import React, { useCallback, useMemo } from "react";
import Image from "../image/image";
import Loader from "../loader/loader";
import Pagination from "../pagination/pagination";
import Text from "../text/text";
import Title from "../title/title";
import styles from "./data-table.module.scss";
import classNames from "classnames";
import NextPrevPagination from "../pagination/next-prev-pagination";
import TableV2 from "../table/table-v2";
import Table from "../table/table";

const DataTableV2 = ({
  name,
  loading,
  pageState,
  applyPage,
  data,
  dataKey,
  columns,
  result,
  hidePagination = false,
  actionHeader = false,
  actionHeaderProps,
  onCheckAll,
  hasColumnPicker,
  paginationV2 = false,
  hideNoSearchResultRender = false,
  tableVersion = "v1",
  ...props
}) => {
  const { page, perPage } = pageState;

  const handlePageChange = useCallback((page) => applyPage && applyPage({ page }), [applyPage]);

  const handlePageSizeChange = useCallback(
    (pageSize) => applyPage && applyPage({ perPage: pageSize, page: 1 }),
    [applyPage]
  );

  const handleCheckAll = useCallback((checked) => onCheckAll && onCheckAll(checked), [onCheckAll]);

  const resultData = useMemo(() => {
    if (props.isReverse) {
      return data.map((_, idx) => {
        const item = data[data.length - (idx + 1)];
        const newItem = new Map();
        columns.forEach(({ key }) => newItem.set(key, item.get(key)));
        newItem.set("className", item.get("className"));
        return newItem;
      });
    }
    return [...data];
  }, [props.isReverse, data, columns]);

  const renderTableContent = useMemo(() => {
    if (loading) return <Loader open={loading} />;

    if (!resultData.length && !hideNoSearchResultRender) {
      return (
        <div className={styles.noSearchResultRender}>
          <div>
            <Image src={GuyWithMagGlass} />
            <Title small>{locale.sorryNoResultFound}</Title>
            <Text color="black" style={{ marginBottom: "10px" }}>
              {locale.weCouldNotFindAMatchForSearchCriteria}
            </Text>
            <Text color="black">{locale.pleaseTryADifferentOne}</Text>
          </div>
        </div>
      );
    }

    const tableProps = {
      ...props,
      columns,
      actionHeaderProps,
      actionHeader,
      onCheckAll: handleCheckAll,
      hasColumnPicker,
      data: resultData,
      isRowCard: props.isRowCard,
    };

    if (tableVersion === "v2") {
      return (
        <div
          className={classNames(
            name && styles.tableContainerColored,
            !name && styles.tableContainer
          )}
        >
          {name && (
            <div className={styles.tableHeader}>
              <Text className={styles.label}>{name}</Text>
            </div>
          )}
          <TableV2 {...tableProps} hasCustomHeader={Boolean(name)} hasCustomFooter />
          {!resultData.length && (
            <div className={styles.tableRow}>
              <Text className={styles.label}>{"No Results"}</Text>
            </div>
          )}
        </div>
      );
    }

    return <Table {...tableProps} />;
  }, [
    loading,
    resultData,
    hideNoSearchResultRender,
    props,
    columns,
    actionHeaderProps,
    actionHeader,
    handleCheckAll,
    hasColumnPicker,
    tableVersion,
    name,
  ]);

  return (
    <div>
      {renderTableContent}
      <div
        className={classNames({
          [styles.hidePagination]: (!data.length && !loading) || hidePagination,
        })}
      >
        {result.count ? (
          <Pagination
            page={page}
            pageSize={perPage}
            className={styles.pagination}
            dataCount={result.count}
            onChangePage={handlePageChange}
            onChangePageSize={handlePageSizeChange}
          />
        ) : (
          <div className="flex" style={{ width: "100%", justifyContent: "flex-end" }}>
            <NextPrevPagination
              {...props}
              page={page}
              currentItemCount={data.length}
              perPage={perPage}
              className={styles.pagination}
              onChange={applyPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DataTableV2;
