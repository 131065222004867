import { useCallback, useContext } from "react";
import useApi from "./useApi";
import { AppContext } from "contexts";
import { getStations } from "apis";

const useStations = () => {
  const { allStations, setAllStations } = useContext(AppContext);

  const getAllStations = useApi({
    api: getStations,
  });

  const fetch = useCallback(
    async () => {
      const res = await getAllStations?.request({
        page: 1,
        perPage: 1000,
      });

      const stations = res.stations;
      setAllStations(stations);

      return stations;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetch,
    allStations,
  };
};

export default useStations;
