import { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmModal from "../confirm-modal/confirm-modal";
import SelectProductsFilter from "./select-products-filter";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "./select-products-filter-state";
import { productsColumn } from "./select-products-columns";
import { mapDataToList, mapFilterToRequest } from "./select-products-mapper";
import { getProductsApi } from "apis";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import locale from "localization";
import { useApi } from "hooks";
import styles from "./select-products-modal.module.scss";
import { handleRequest } from "utils";
import { prettifyProductType } from "utils/pretty.utils";

const SelectProductsModal = (
  modal = {
    onFetch: (allProducts) => 0,
    allProducts: [],
  }
) => {
  const [allProducts, setAllProducts] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selected, setSelected] = useState(modal?.value || []);

  const onCheck = (sku) => {
    if (selected.includes(sku)) {
      setSelected((s) => s.filter((selectedSku) => selectedSku !== sku));
    } else {
      setSelected((s) => [...s, sku]);
    }
  };

  const handleOnCheckAll = () => {
    setIsCheckAll(!isCheckAll);
    let result = [];
    if (isCheckAll) {
      result = [];
    } else {
      result = allProducts?.map(({ sku }) => sku);
    }

    setSelected(result);
  };

  const onClickSave = () => {
    modal?.onChange?.(
      selected
        .map((name) => modal?.allProducts.find(({ sku }) => sku === name))
        .filter((product) => !!product)
    );
  };

  const uniqueProductTypes = useMemo(() => {
    const all = modal?.allProducts || allProducts;
    const options = all.reduce((acc, { category, type }) => {
      if (!acc.some((p) => p.value === type)) {
        acc.push({ label: prettifyProductType(type), value: type });
      }
      return acc;
    }, []);
    return options;
  }, [allProducts, modal.allProducts]);

  const { filter, search, table } = useDataTable({
    api: {
      api: getProductsApi,
      mapper: (param) => param,
    },
    filter: {
      initialState: {
        ...initialFilterState(),
        productType: {
          isSelectedAll: true,
          value: uniqueProductTypes,
        },
      },
      mapper: mapFilterToRequest,
    },
    table: {
      key: "products",
      mapper: (product) =>
        mapDataToList({
          product,
          onCheck,
          selected,
        }),
      columns: productsColumn,
    },
  });

  const getAllProducts = useApi({
    api: getProductsApi,
  });

  const fetchAllProducts = useCallback(
    async (filterState) => {
      const res = await getAllProducts?.request({
        ...mapFilterToRequest(filterState),
        perPage: 1000,
      });

      const products = res.products;
      if (selected.length > products.length && isCheckAll) {
        setSelected(res.products?.map((product) => product.sku));
      }

      return products;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCheckAll, selected]
  );

  useEffect(() => {
    handleRequest(async () => {
      search?.applyClearSearch();
      if (modal?.allProducts && modal?.allProducts?.length > 0) {
        setAllProducts(modal?.allProducts || []);
      } else {
        const allProductsRes = await fetchAllProducts(initialFilterState());
        setAllProducts(allProductsRes || []);
        modal?.onFetch?.(allProductsRes);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.allProducts]);

  useEffect(() => {
    if (modal?.active && modal?.value && modal?.value?.length >= 0) {
      setSelected(modal?.value);
    }
  }, [modal?.value, modal?.active]);

  useEffect(() => {
    if (modal?.active) {
      search?.applyClearSearch();
      setIsCheckAll(false);
      filter.applyClearFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.active]);

  const applyClearSearch = async () => {
    setAllProducts(
      await fetchAllProducts({
        ...filter.filterState,
        searchKey: undefined,
        perPage: 1000,
        id: "all",
      })
    );
    search.applyClearSearch();
  };

  const applyFilter = async () => {
    setAllProducts(
      await fetchAllProducts({
        ...filter.filterState,
        searchKey: "",
        perPage: 1000,
        id: "all",
      })
    );
    filter?.applyFilter();
  };

  const applySearch = async () => {
    setAllProducts(
      await fetchAllProducts({
        ...filter.filterState,
        searchKey: search.searchKey,
        perPage: 1000,
        id: "all",
      })
    );

    search.applySearch();
  };

  const applyClearFilter = async () => {
    setAllProducts(
      await fetchAllProducts({
        ...initialFilterState(),
        searchKey: search.searchKey || undefined,
        perPage: 1000,
        id: "all",
      })
    );

    filter.applyClearFilter();
  };

  return (
    <ConfirmModal
      {...modal}
      className={styles.modal}
      primary={{ text: locale.save, disabled: selected.length === 0, onClick: onClickSave }}
      secondary={{ text: locale.cancel }}
    >
      <SelectProductsFilter
        {...filter}
        {...search}
        applyClearSearch={applyClearSearch}
        applySearch={applySearch}
        applyFilter={applyFilter}
        applyClearFilter={applyClearFilter}
        actionText={<locale.Populate text={locale.nSelected} items={[selected.length]} />}
        actionProps={{
          link: selected.length > 0,
          variant: selected.length === 0 && "disabled",
        }}
        actionOnClick={() => {
          setSelected([]);
          setIsCheckAll(false);
        }}
        uniqueProductTypes={uniqueProductTypes}
      />
      <DataTableV2
        {...table}
        onCheckAll={handleOnCheckAll}
        actionHeaderProps={{
          checked: isCheckAll,
        }}
        size="small"
      />
    </ConfirmModal>
  );
};

export default SelectProductsModal;
