const StationType = {
  COCO: "COCO",
  CODO: "CODO",
  CODOX: "CODOX",
  COXO: "COXO",
  WIBCO: "WIBCO",
  WILCO: "WILCO",
  WIDOX: "WIDOX",
  DODO: "DODO",
  DODOX: "DODOX",
  XDODO: "XDODO",
  XWIDO: "XWIDO",
  XDODO_W: "XDODO-W",
  XWIDO_W: "XWIDO-W",
  UDODOX: "UDODOX",
  CODOL: "CODOL",
  UWIDOX: "UWIDOX",
};

export default StationType;
