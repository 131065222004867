import {
  capitalize,
  isDriverLicenseIdValid,
  isEmailValid,
  isMobileNumberValid,
  isAlphaNumeric,
  isNameValid,
  isNumeric,
  isPasswordValid,
  isTelephoneNumber,
} from "./text.utils";
import locale from "localization";
import { parseNumber } from "./number.utils";
import { isDate } from "lodash";

const Validation = {
  required: (custom = {}) => {
    const { customMessage = null, noMessage = false } = custom;
    return (field) => {
      const value = field?.value?.toString().trim();
      if (!value) {
        if (noMessage) return { error: true, value: "" };
        return {
          error: true,
          message: customMessage ? customMessage : "This field is required.",
          value: "",
        };
      }
      return { error: false, message: null };
    };
  },
  requiredArray: (custom = {}) => {
    const { customMessage = null, noMessage = false, notEmpty = false } = custom;
    return (field) => {
      const value = field?.value?.length > 0;
      if (!value || (value === 0 && notEmpty)) {
        if (noMessage) return { error: true, value: [] };
        return {
          error: true,
          message: customMessage ? customMessage : "This field is required.",
          value: [],
        };
      }
      return { error: false, message: null };
    };
  },
  requiredMultiDropdown: (custom = {}) => {
    const { customMessage = null, noMessage = false } = custom;
    return (field) => {
      const value = field?.value;
      if (typeof value !== "object") {
        if (noMessage) return { error: true };
        return {
          error: true,
          message: customMessage ? customMessage : "This field is required.",
        };
      }
      return { error: false, message: null };
    };
  },
  differentiate: (custom = {}) => {
    const { noMessage = false, initialValue, label } = custom;
    return (field) => {
      if (parseNumber(initialValue) !== parseNumber(field?.value)) {
        if (noMessage) return { error: true };
        return {
          error: true,
          message: `Update to ${label ?? initialValue}`,
        };
      }
      return { error: false, message: "" };
    };
  },
  priceChange: () => {
    return (field) => {
      if (parseFloat(field?.value) > 0) {
        return {
          fontColor: "red",
          message: " ",
          error: false,
        };
      } else if (parseFloat(field?.value) < 0) {
        return {
          fontColor: "green",
          message: " ",
          error: false,
        };
      }

      return {
        fontColor: "black",
        message: " ",
        error: false,
      };
    };
  },
  maxlength: (length = 0, customMessage = null) => {
    return (field) => {
      if (field.value.toString().length > length) {
        return {
          error: true,
          message: customMessage ? customMessage : `Maximum ${length} character(s).`,
        };
      }
      return { error: false, message: null };
    };
  },
  minlength: (length = 0, customMessage = null, showMessage = true) => {
    return (field) => {
      if (field.value && field.value.length < length) {
        const message = customMessage ? customMessage : `Minimum ${length} character(s).`;
        return {
          error: true,
          message: showMessage ? message : "",
        };
      }
      return { error: false, message: null };
    };
  },
  emailAddress: (o) => {
    const { customMessage, noMessage = false } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !isEmailValid(fieldValue)) {
        if (noMessage) return { error: true };
        return {
          error: true,
          message: customMessage || "This email is invalid.",
        };
      }
      return { error: false, message: "" };
    };
  },
  rangeValue: (min = 0, max = 1, customMessage = null) => {
    return (field) => {
      if (parseFloat(field.value) < min || parseFloat(field.value) > max) {
        return {
          error: true,
          message: customMessage ? customMessage : `Value should be between ${min}-${max}`,
        };
      }
      return { error: false, message: null };
    };
  },
  max: (max = 1, o) => {
    const { customMessage, noMessage = false, isFormatted } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      let fv = fieldValue;
      if (isFormatted) {
        fv = parseNumber(fv);
      }
      if (fv && Number(fv) > max) {
        if (noMessage) return { error: true };
        return {
          error: true,
          message: customMessage || `Maximum allowed is ${max}.`,
        };
      }
      return { error: false, message: "" };
    };
  },
  dateRangeRequired: (o) => {
    const { customMessage, noMessage = true } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (!fieldValue.startDate || !fieldValue.endDate) {
        if (noMessage) return { error: true };
        return {
          error: true,
          message: customMessage || "This is required.",
        };
      }
      return { error: false, message: "" };
    };
  },
  min: (min = 1, o) => {
    const { customMessage, noMessage = false, isFormatted } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      let fv = fieldValue;
      if (isFormatted) {
        fv = parseNumber(fv);
      }
      if (fieldValue?.toString().trim() && Number(fv) < min) {
        if (noMessage) return { error: true };
        return {
          error: true,
          message: customMessage || `Minimum allowed is ${min}.`,
        };
      }
      return { error: false, message: "" };
    };
  },
  number: (customMessage = null) => {
    return (field) => {
      if (isNaN(field.value)) {
        return {
          error: true,
          message: customMessage ? customMessage : `Should be a number.`,
        };
      }
      return { error: false, message: null };
    };
  },
  reserveWord: (words = [], customMessage = null) => {
    return (field) => {
      if (words.length > 0 && words.includes(field.value.toString().toLowerCase())) {
        return {
          error: true,
          message: customMessage ? customMessage : `${capitalize(field.value)} is a reserve word.`,
        };
      }
      return { error: false, message: null };
    };
  },
  driverLicenseId: (o) => {
    const { customMessage, noMessage = false, removeLast = true } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !isDriverLicenseIdValid(fieldValue)) {
        if (noMessage) return { error: true };
        const text = fieldValue.replace(/[^a-z0-9]/gi, "");
        return {
          error: true,
          message: customMessage || locale.driverLicenseIdIsInvalid,
          value: removeLast ? text : fieldValue,
        };
      }
      return { error: false, message: "" };
    };
  },
  numeric: (o) => {
    const { customMessage, noMessage = false, removeLast = true } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !isNumeric(fieldValue)) {
        if (noMessage) return { error: true };
        const text = fieldValue.replace(/[^0-9]/gi, "");
        return {
          error: true,
          message: customMessage || locale.onlyNumericAreAllowed,
          value: removeLast ? text : fieldValue,
        };
      }
      return { error: false, message: "" };
    };
  },
  mobileNumber: (o) => {
    const { customMessage, noMessage = false, removeLast = true } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !isMobileNumberValid(fieldValue)) {
        if (noMessage) return { error: true };
        const text = fieldValue.replace(/[^0-9]/gi, "");
        return {
          error: true,
          message: customMessage || locale.pleaseEnterAValidMobileNumber,
          value: removeLast ? text : fieldValue,
        };
      }
      return { error: false, message: "" };
    };
  },
  telephoneNumber: (o) => {
    const { customMessage, noMessage = false, removeLast = true } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !isTelephoneNumber(fieldValue)) {
        if (noMessage) return { error: true };
        const text = fieldValue;
        return {
          error: true,
          message: customMessage || locale.pleaseEnterAValidTelephoneNumber,
          value: removeLast ? text : fieldValue,
        };
      }
      return { error: false, message: "" };
    };
  },
  alphaNumeric: (o) => {
    const { customMessage, noMessage = false, removeLast = true } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !isAlphaNumeric(fieldValue)) {
        if (noMessage) return { error: true };
        const text = fieldValue.replace(/[^a-z0-9]/gi, "");
        return {
          error: true,
          message: customMessage || locale.onlyAlphaNumericAllowed,
          value: removeLast ? text.trim() : fieldValue.trim(),
        };
      }
      return { error: false, message: "", value: fieldValue.trim() };
    };
  },
  name: (o) => {
    const { customMessage, noMessage = false, removeLast = true } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !isNameValid(fieldValue)) {
        if (noMessage) return { error: true };
        const text = fieldValue.replace(/[^A-Za-z0-9Ññ.-\s-]/gi, "");
        return {
          error: true,
          message: customMessage || locale.onlyAlphaNumericAllowedAndSpecial,
          value: removeLast ? text : fieldValue,
        };
      }
      return { error: false, message: "", value: fieldValue };
    };
  },
  password: (o) => {
    const { customMessage, noMessage = false } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !isPasswordValid(fieldValue)) {
        if (noMessage) return { error: true };
        return {
          error: true,
          message: customMessage || locale.use8CharWithMix,
          value: fieldValue,
        };
      }
      return { error: false, message: "", value: fieldValue.trim() };
    };
  },
  minDate: (o) => {
    const { customMessage, noMessage = false, startDate = null } = o || {};
    return (field) => {
      const { value: fieldValue } = field;
      if (
        fieldValue &&
        isDate(fieldValue) &&
        startDate &&
        isDate(startDate) &&
        startDate > fieldValue
      ) {
        if (noMessage) return { error: true };
        return {
          error: true,
          message: customMessage || locale.minDateError,
          value: fieldValue,
        };
      }

      return { error: false, message: "", value: fieldValue };
    };
  },
};

export default Validation;
