import { Checkbox } from "components/commons";

export const mapDataToList = ({ station, onCheck, selected }) => {
  return {
    select: (
      <Checkbox
        name={station?.name}
        value={selected?.includes(station?.stationCode)}
        onChange={(name) => onCheck?.(station?.stationCode)}
      />
    ),
    stationCode: station?.stationCode,
    stationName: station?.name,
    stationType: station?.type,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { stationType, ...fs } = filterState;

  if (stationType?.value?.length > 0) {
    fs.type = stationType?.value?.join(",");
  }

  if (stationType?.isSelectedAll) {
    fs.type = undefined;
  }

  return fs;
};
