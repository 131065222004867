import { useCallback, useContext } from "react";
import useApi from "./useApi";
import { AppContext } from "contexts";
import { getProductsApi } from "apis";

const useProducts = () => {
  const { allProducts, setAllProducts } = useContext(AppContext);

  const getAllProducts = useApi({
    api: getProductsApi,
  });

  const fetch = useCallback(
    async () => {
      const res = await getAllProducts?.request(
        {
          page: 1,
          perPage: 1000,
        },
        () => 0,
        { useLoader: true }
      );

      const products = res.products;
      setAllProducts(products);

      return products;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetch,
    allProducts,
    loading: getAllProducts.loading,
  };
};

export default useProducts;
