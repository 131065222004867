import locale from "localization/en";

export const stationsColumn = [
  { key: "code", text: locale.stationCode },
  { key: "name", text: locale.stationName },
  { key: "type", text: locale.stationType },
  { key: "subscription", text: locale.subscription },
  { key: "onboardedBy", text: `${locale.onboardedBy} / ${locale.onboardedOn}` },
  { key: "status", text: `${locale.status} / ${locale.dateOnboarded}` },
  { key: "actions", text: locale.actions },
];
